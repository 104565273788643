@import './Topbar/Topbar';
@import './SearchContainer/SearchContainer';
@import './Table/Table';
@import './CommonTable/CommonTable';

.field-wrapper {
  margin: 5px 0px;
}
.field-label {
  font-size: 11px;
  color: grey;
}

.pointer {
  cursor: pointer;
}

.element-disabled {
  pointer-events: none;
  opacity: 0.3 !important;
}

.content-header {
  font-weight: bold;
  color: #822433;
}

.responsive-modal-small {
  width: 30% !important;
}
.responsive-modal-medium {
  width: 50% !important;
}
.responsive-modal-large {
  width: 70% !important;
}

@media (max-width: 1400px) {
  .responsive-modal-large,
  .responsive-modal-medium,
  .responsive-modal-small {
    width: 70% !important;
  }
}

@media (max-width: 768px) {
  .responsive-modal-large,
  .responsive-modal-medium,
  .responsive-modal-small {
    width: 80% !important;
  }
}