.top-menu-bar {
    background-color: #822533;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    position: -webkit-sticky;
    position:sticky;
    top: 0;
    z-index: 1;
    overflow-y: hidden;
    overflow-x: auto;

    .app-logo {
        width: 16rem;
    }
    .app-menu-name {
        color: #ffffff;

        .divider {
            margin: 0px 7px 1px;
        }
    }
    .app-menu {
        display: flex;
        align-items: center;
        color: #ffffff;

        a {
            text-decoration: none;
            color: #ffffff;
        }
        .app-menu-item {
            margin: 0rem 1rem;
        }
        .logout-icon {
            width: 2rem;
            fill: #ffffff;
        }
    }
}

@media (max-width: 768px) {
    .top-menu-bar {
        height: 75px;

        .app-logo {
            width: 11rem;
        }
        .app-menu-name {
            font-size: 14px;
        }
        .app-menu {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .app-menu-item {
                margin: 0rem 3px;
                font-size: 13px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
  .deskTopView {
    display:block !important;
  }
  .mobileView {
      display: none !important;
  }
}

@media only screen and (min-width: 280px) and (max-width:1024px) {
  .deskTopView {
    display:none !important;
  }
  .mobileView {
      display: block !important;
  }
}


.ant-drawer-content-wrapper {
    .ant-drawer-header {
        background: #822533;
        .ant-drawer-title {
            color:#fff;
            font-size: 20px;
            svg{
                cursor: pointer;
            }
        }
    }
    .ant-drawer-body {
        padding-top: 0;
        .ant-list-items {
            a {
                text-decoration: none;
                .app-menu-item {
                    padding: 10px 5px;
                    border-bottom: 1px solid #d9d9d9;
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 14px;
                }
            }
        }
    }
}