.leadership-wrapper{
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item{
        text-align: start;
        padding-left: 1rem;
    }
    .disableButton{
        opacity: 0.5;
        pointer-events: none;
    }
}
.direction-icon {
        padding-bottom: 5px;
        border-radius: 4px;
        border: solid 1px lightgray;
        cursor: pointer;
        &:hover{
            border: solid 1px grey;
        }
    }
.leaderBoardModal .anticon {
    width: 1.5rem;
    cursor: pointer;
  }
.LeaderShip-table{
    .table-wrapper{
        margin: 0;
    }
    .ant-empty-normal {
        height: 700px
    } 
}
.leader-plus-icon{
  font-size: 18px;
  color: gray;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}
.removecheckbox{
    display: none;
}
