@import './Components/Components';
@import './Containers/Containers';
@import './bootstarp';
@import './ant.customize';
@import 'antd/dist/antd.css';

body {
    height: 100%;
    margin: 0px;
    font-family: Arial,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.nav-link {
    text-decoration: none;
    color: inherit;
    padding: 0px;
    margin:0.5rem 1rem;

    &:hover {
        color: inherit;
    }
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

$colours: "1_on_1" #c2e2bf, "white" #ffff, "black" rgb(0, 0, 0), "buyer" #bddff6,
  "general" #d1d3d4, "listing" #fcf7bc, "darkenListing" #c4bc6d,
  "rental_open_home" #d0bedd, "open_home" #f37d80, "market_review" #ffdfba,
  "rental" #f8b1b9, "rental_open_home" #d0bedd, "seller_visit" #ab9ecc,
  "tenant" #d789ba, "virtual_listing" #88cfbd, "virtual_buyer" #8abde6,
  "brand" #822433, "red" #f44336, "blue" #463bdd, "darkRed" #4c0e1b,
  "darkGrey" #414042, "darkBlue" #c0d9e6, "lightBlue" #eff9fe, "green" #2bb673,
  "lightGrey" #a7a9ac, "landlord" #f8b1b9, "prospect" #fcf7bc, "seller" #ffdfba,
  "darkenSeller" #ffdfba, "servicer" #d1d3d4;

$viewPort: 50vh, 60vh, 70vh, 80vh;

@each $view in $viewPort {
  .view-#{$view} {
    height: $view;
  }
}

@each $i in $colours {
  .#{nth($i, 1)}-background {
    background-color: nth($i, 2) !important;
  }

  .#{nth($i, 1)}-color {
    color: nth($i, 2) !important;
  }

  .#{nth($i, 1)}-border-left {
    border-left: 4px solid nth($i, 2);
    height: -webkit-fill-available;
  }

  .#{nth($i, 1)}-border {
    border: 1px solid nth($i, 2);
  }

  .#{nth($i, 1)}-border-2 {
    border: 2px solid nth($i, 2);
  }

  .#{nth($i, 1)}-button {
    max-height: 34px;
    background-color: nth($i, 2) !important;
    font-weight: bold !important;

    &:hover {
      background-color: darken(nth($i, 2), 10%) !important;
      color: white !important;
    }

    &.active {
      background-color: darken(nth($i, 2), 10%) !important;
      color: white !important;
    }
    &.ant-btn[disabled] {
      background-color: #FFFFFF !important;
      color: grey !important;
      border: solid 2px #ccd1d7;
    }
  }

  .#{nth($i, 1)}-icon-button {
    color: nth($i, 2) !important;

    &:hover {
      color: darken(nth($i, 2), 10%) !important;
    }

    &.active {
      color: darken(nth($i, 2), 10%) !important;
    }

    &:disabled {
      color: lighten(nth($i, 2), 50%) !important;
    }
  }

  .#{nth($i, 1)}-svg-button {
        fill: nth($i, 2) !important;

        &:hover {
        fill: darken(nth($i, 2), 10%) !important;
        }

        &.active {
        fill: darken(nth($i, 2), 10%) !important;
        }
    }
}

@media (max-width: 770px) {
  .mt-xs-3 {
    margin-top: 1rem;
  }
}

.header-text{
  color: #822433;
  font-weight: bold;
  font-size: 18px;
  margin-top: 25px;
}
.font-weight-bold{
  font-weight: bold;
}
.cursor-pointer{
  cursor: pointer;
}