.table-wrapper {
    margin: 20px;

    .ant-table-wrapper {
        .ant-table-container {
            border: 2px solid #822533;

            // .ant-table-thead,
            // .ant-table-tbody {
            //     .ant-checkbox-checked {
            //         .ant-checkbox-inner {
            //             background-color: #137cbd;
            //             border-color: #137cbd;
            //         }
            //     }
            // }
            .ant-table-cell-scrollbar {
                box-shadow: none;
            }

            .ant-table-thead {

                // th+th {
                //     border-left: 1px solid #822533;
                // }
                th {
                    background-color: #822533;
                    color: #ffffff;
                    padding: 8px 15px;
                    text-align: center;
                }
            }

            .ant-table-tbody {

                tr,
                .ant-table-row {
                    td+td+td {
                        border-left: 1px solid #822533;
                        border-right: 1px solid #822533;
                    }

                    td {
                        padding: 8px 15px;
                    }

                    .drag-visible,
                    .ant-table-selection-column {
                        border: none;
                    }

                    td:last-child {
                        border: none;
                    }
                }

                tr:nth-child(even) {
                    background-color: #eff9fe;
                }

                td.ant-table-column-sort,
                tr.ant-table-row-selected>td {
                    background-color: inherit;
                }
            }
        }
    }

    .ant-table-tbody>tr.ant-table-row:hover>td,
    .ant-table-tbody>tr>td.ant-table-cell-row-hover {
        background: transparent;
    }

    .ant-table-tbody>tr>td {
        border: none;
    }
}

.margin-small {
    .table-wrapper {
        margin: 0.25rem;
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging {
    td {
        padding: 8px 15px;
    }

    .drag-visible {
        visibility: hidden;
    }
}