.content-section {
  // height: 500px;
  // background-color: #d1d1d1;
  width: 80%;

  &.event-content-section {
    .ant-tabs-content {
      height: 100%;
    }
  }
}
.event-card {
  background-color: #f0f9fe;
  padding: 15px 20px;
  margin: 25px 0px;
  .ant-collapse-header {
    padding: 0 !important;
    align-items: center !important;
    .ant-collapse-arrow {
      position: absolute;
      left: 165px;
      top: 42%;
    }
  }
}
.gray-caption{
  color: gray;
  font-size: small;
}

.container-wrapper {
  height: calc(100% - 60px);
  overflow-y: auto;

  .content-wrapper {

    .ant-spin-container {
      height: 100%;
    }

    .event_container {
      height: calc(100vh - 269px);
      overflow-y: auto;
    }
  }
}

@media (max-width: 768px) {
  .container-wrapper {
    height: auto;

    .content-wrapper {
      height: auto;

      .event_container {
        height: 100vh;
        overflow-y: auto;
      }
    }
  }
}

.time-input .ant-picker-time-panel-column::after {
  height: 0px;
}