
  .support-material-container {
      display: flex;
      flex-wrap: wrap;
      bottom: 100px;
      width: 50rem;
      justify-content: center;

      .menu-items {
          padding: 0.5rem;
          background-color: #e4eef3;
          width: 11rem;
          height: 11rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 1rem;
  
          .menu-title {
              font-size: 16px;
              font-weight: bold;
              margin-top: 10px;
          }
  
          .menu-icon {
              height: 4rem;
              width: 4rem;
  
              svg {
                  fill: #444444;
              }
          }
      }
  }
.support-material-primary-icons{
    height: 20px; 
    width: 20px; 
}