.result-page-wrapper {
  .home-menu-container {
      display: flex;
      flex-wrap: wrap;
      // width: 60rem;
      justify-content: center;

      .menu-items {
          padding: 0.5rem;
          background-color: #e4eef3;
          width: 11rem;
          height: 11rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 1rem;
  
          .menu-title {
              font-size: 16px;
              font-weight: bold;
              margin-top: 10px;
          }
  
          .menu-icon {
              height: 4rem;
              width: 4rem;
  
              svg {
                  fill: #444444;
              }
          }
      }
  }
  .footer-link {
      display: flex;
      font-weight: bold;
      justify-content: space-between;
      padding: 1rem;
  }
}

@media (max-width: 768px) {
  .result-page-wrapper {
      .search-container-wrapper {
          padding-bottom: 6rem;
      }

      .home-menu-container {
          width: 40rem;
          justify-content: center;
      }
  }
}

.count-container{
    border: solid 2px #E3EEF3;
    border-radius: 4px;
    height: 2.5rem;
    font-size: 12px;
    font-weight: bold;
    min-width: 7rem;
}
.result-office {
    .form-select-label{
        margin-top: 6px;
  }
}