.wol-body {
  height: 100vh;
  display: flex;
  color: #ffffff;
  background-size: cover;
  .left {
    padding: 2rem 3.5rem;
    background-color: #822433d1;
    min-width: 500px;
    flex: 1.16;
    .h-95 {
      height: 95%;
    }
    .admin-panel {
      font-size: 25px;
    }
    .wol-link {
      text-decoration: none;
      color: inherit;
    }
  }
  .right {
    flex: 3;
    .login-form {
      background-color: #ffffff;
      color: #822433;
      width: 500px;
      padding: 50px 25px;
    }
  }
}
.login-btn {
  height: 50px;
  background-color: #822433;
  font-size: 14px !important;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .admin-panel {
    display: none;
  }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  .wol-body {
    flex-direction: column;
    .left {
      min-width: auto;
      padding: 24px 18px;
      align-items: center;
      flex: 0.7;
      justify-content: space-evenly !important;
      .extra-node {
        display: none;
      }
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  .login-form {
    width: 475px !important;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (max-width: 1400px) {

}