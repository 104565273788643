.ant-input, .ant-input-password {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #8d8d8d;
  padding-left: 0px;
  // font-size: 13px;
}
.ant-input-password {
  .ant-input {
    border: none;
  }
}
.ant-input-password {
  .ant-input-suffix {
    display: none;
  }
}
.ant-input:hover, .ant-input-password:hover {
  border-color: #8d8d8d;
}
.ant-input:focus, .ant-input-focused, .ant-input-affix-wrapper-focused {
  border-color: #8d8d8d !important;
  box-shadow: none !important;
}
.ant-btn {
  font-weight: bold;
  font-size: 13px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #822433;
}
.ant-btn:hover {
  border-color: #8d8d8d;
  color: #ffffff;
  background-color: #822433;
}
.ant-btn:active, .ant-btn:focus {
  border-color: #d3d2d2;
  color: #ffffff;
  background-color: #822433;
}

.ant-picker{
  border: none;
  border-bottom: 1px solid #8d8d8d;;
}
.ant-picker-focused{
  box-shadow: none;
  border-color: none;
}

.form-select-field{
  .form-select-label{
      font-size: 11px;
      color: grey;
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    left: 0px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-bottom: 1px solid #8d8d8d;
    border-top: none;
    border-right: none;
    border-left: none;
    padding-left: 0px;
  }
  .ant-select-focused:not(.ant-select-disable).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #8d8d8d;
    box-shadow: none;
  }
  .ant-select:not(.ant-select-disable):hover .ant-select-selector {
    border-color: #8d8d8d;
  }
   .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
    width: 100% !important;
    height: 28px !important;
    padding: 0px !important;
    font-size: 12px;
  }
}

.customise-border-tosuffixprefix {
  border: none;
  border-bottom: 1px solid #969696;
  padding-left: 0px;
  padding-right: 0px;
}
.select-users{
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
    padding-right: 0px;
    padding-left: 0px;
  }
}
.ant-form-item .ant-select :focus{
  border: none;
 }


.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list{
  border:1px solid;
  border-radius: 5px;
}
.ant-tabs-tab {
  padding: 3px 10px !important;
  min-width: 100px;
  justify-content: center;
  :hover, :focus {
    color: #000000;
  }
}

.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0px;
  border: none;
  background-color: transparent ;
  // border-left: 1px solid #000000;
}
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border: none;
  background-color: #e5f3f9;
  border-radius: 5px 0px 0px 5px;
  .ant-tabs-tab-btn {
    color: #000000;    
  }
}
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border:none;
  font-size: 15px;
  background: transparent;
}

#itemsForm .ant-upload-drag{
  border: 2px dashed #ebedf3;
}

.customise-border-tosuffixprefix{
  border: none;
  border-bottom: 1px solid #969696;
  padding-left: 0px;
  padding-right: 0px;
}

#resourceModal .thumbNailContainer, #categoryModal .thumbNailContainer{
  padding: 45px 0px 0px 35px;
  .ant-upload-picture-card-wrapper {
    .image-icon-preview {
        position: relative;
        background-color: #C1DAE6;
        display: flex;
        align-items: center;
        justify-content: center;
        .image-edit {
          height: 175px;
          border-radius: 6px;
          width:175px;
        }
        .preview-icon {
          display: none;
          position: absolute;
          background-color: #00000066;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          svg {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
      }
      .image-icon-preview:hover {
        .preview-icon {
          display: flex;
          border-radius: 6px;
      }
    }
  }
}

#resourceModal {
  .ant-upload-drag {
      overflow-x: auto;
      overflow-y: hidden;
      border: 2px dashed #ebedf3;
    }
  .editFile {
    border: 2px solid #c1dae6;
    .ant-upload-drag-container {
      display: flex;
      justify-content: space-between;
      height: 100px;
      border-radius: 6px;
    }
    .ant-upload-btn {
      background-color: #fff;
      padding: 0;
    }
    .fileObj {
      justify-content: space-between; 
      width: 100%;
      cursor: default;
      .fileInfoParent,.actionInfo {
        cursor: pointer;
      }
      .fileInfoParent{
        padding: 24px;
        .fileInfo {
          margin-left: 10px;
          span:last-child{
            font-size: 12px;
          }
          span:first-child{
            font-size: 15px;
          }
        }
        .pdfIcon {
          width: 3.5rem;
        }
      }
      .actionInfo {
        padding: 25px;
        .iconInfo {
          span{
            width:1.3rem;
          }
          span:first-child{
            margin-right: 5px;
          }
        }
        p{
          font-size: 12px;
        }
      }
    }
  }
  .uploadIcon {
    width: 1.5rem;
  }
}

.ant-form-item-explain-error {
  font-size: 12px;
}

#usersList, #organisationList,#resultsList,#leaderList,#recruitmentList,#franchiseReports {
  .ant-table-cell{
    text-align: center;
  }
  .ant-table-cell-partner-empty{
    color: #ff4d4f;
  }
  overflow-x: auto;
  .ant-input-affix-wrapper {
    border: 0;
    border-bottom: 1px solid #969696;
    border-radius: 0;
    padding-left: 0;
    width: 250px;
    .ant-input-suffix {
      svg {
        fill: #a7a9ac;
      }
    }
  }
  .filterBtn {
    background-color: #414042!important;
    width: 100px;
  }
  .anticon {
    width: 1.5rem;
    cursor: pointer;
  }
  .toggleFilter{
    .ant-radio-group-solid {
      border-radius: 6px;
      .ant-radio-button-wrapper-checked {
        background: #c0d9e6!important;
        border-color: #c0d9e6!important;
      }
      .ant-radio-button-wrapper-checked:before {
        background: none !important;
      }
      .ant-radio-button-wrapper {
        padding: 0 25px;
        color: #000;
        font-weight: bolder;
        height: 28px;
        line-height: 27px;
        font-size: 13px;
        width: 100px;
      }
      .ant-radio-button-wrapper:focus {
       border: 0;
      }
    }
  }
  .table-wrapper {
    margin: 0;
  }
}
  .ant-tabs-card {
    .tabHeader{
      overflow-y: hidden;
      overflow-x: auto;
    }
    .ant-tabs-nav {
      margin: 0;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
           border-radius: 4px;
           border-color: #c0d9e6;
           height: 30px;
          .ant-tabs-tab.ant-tabs-tab-active{
            background-color: #c0d9e6;
          }
          .ant-tabs-tab {
            border: 0;
            border-radius: 0;
          }
        }
      }
    }
    .ant-tabs-content-holder {
      margin-top:10px;
      // .statusContainer{
      //   button{
      //     height: 25px;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //   }
      // }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
    box-shadow: none;
    border-bottom:1px solid #969696;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border: none;
    box-shadow: none;
    border-bottom:1px solid #969696;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
    border-bottom:1px solid #969696;
  }
  img, svg{
    vertical-align: baseline;
  }
  .ant-select-multiple .ant-select-selection-item{
    background: #e7e7e8;
    border-radius: 10px;
    line-height: normal !important;
  }
  .ant-picker:hover, .ant-picker-focused{
      border-color: #969696;
  }