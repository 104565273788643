.common-table-wrapper {
  .header-plus-icon {
    font-size: 18px;
    color: #2bb673;
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  // table {
  //   border: 2px solid #822533;
  //   width: 100%;

  //   thead {
  //     background-color: #822533;
  //     color: #ffffff;
  //     padding: auto;
  //   }
  //   tr {
  //     height: 2.1rem;
  //   }
  // }

  table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    border: 2px solid #822533;
    tr {
      cursor: pointer;
    }
    .selected-tr {
      background-color: #b1cedd;
    }
    th,td{
      padding: 10px 20px;
      position: relative;
      font-weight: 500;
      border-bottom: 1px solid #f0f0f0;
      transition: background 0.3s ease;
      overflow-wrap: break-word;
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      font-size: 14px;
    }
    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
    }
    tbody {
      display: block;
      overflow: auto;
    }
    th{
      background-color: #822533 !important;
      color: #ffffff;
      font-size: 15px;
      text-align: center;
    }
    td{
      text-align:left;
      height: 42px;
    }
    tbody td:not(:last-child) {
      border-right:1px solid #822533;
    }
    tbody tr:nth-child(even) {
      background-color: #eff9fe;
    }
    // .td-checkbox {
    //   border-right: none !important;
    //   padding: 10px;
    //   width: 30px;
    // }
    // .th-checkbox {
    //   width: 30px;
    //   text-align: left;
    //   padding: 10px;
    // }
  }
}