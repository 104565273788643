.home-page-wrapper {
  .search-container-wrapper {
      padding: 8rem 0rem 10rem;
  }
  .home-menu-container {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      bottom: 100px;
      width: 50rem;
      justify-content: center;

      .menu-items {
        //   padding: 0.5rem;
          background-color: #e4eef3;
          // background-color: #e6f8ff;
          // border: 1px solid #555555;
          width: 11rem;
          height: 11rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        //   margin: 1rem;
  
          .menu-title {
              font-size: 16px;
              font-weight: bold;
              margin-top: 10px;
          }
  
          .menu-icon {
              height: 4rem;
              width: 4rem;
  
              svg {
                  fill: #444444;
              }
          }
      }
  }
  .footer-link {
      display: flex;
      font-weight: bold;
      justify-content: space-between;
      padding: 1rem;
  }
}

@media (max-width: 768px) {
  .home-page-wrapper {
      .search-container-wrapper {
          padding-bottom: 6rem;
      }

      .home-menu-container {
          position: relative;
          bottom: 50px;
          width: 40rem;
          justify-content: center;
      }
  }
}

@media (max-width: 425px) {
  .home-page-wrapper {
      .footer-link {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
      }
  }
}
