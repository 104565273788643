.search-container-wrapper {
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% auto;
  text-align: center;
  padding: 6rem 0rem;

  .title {
      color: #822533;
      font-size: 30px;
      font-weight: bold;
      margin: 1rem 0rem;
  }

  .search-field {
      width: 36rem;

      .ant-btn {
          background-color: #b8dceb;
      }
  }
}

@media (max-width: 768px) {
  .search-container-wrapper {
      background-size: 100% 100%;
      padding: 2rem 10px 7rem 10px;
      .title {
          font-size: 20px;
      }
      .search-field {
          width: 100%;
      }
  }
}
